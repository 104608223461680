<template>
  <div class="my-3 mx-4">
    <ValidationObserver
      ref="dateObserver"
      tag="form"
      id="locationDetailForm"
      @submit.prevent="onSubmit"
    >
      <div class="row">
        <div class="col-12">
          <FormErrorAlert
            v-if="errors.length > 0"
            :errors="errors"
            :formRef="this.$refs.dateObserver"
          />
          <div class="card">
            <div class="card-header">
              <div class="card-title mb-0">{{ heading }}</div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="form-group pt-3 col-lg-4">
                  <ValidationProvider
                    rules="required"
                    name="Location"
                    v-slot="{ errors, ariaInput, ariaMsg }"
                  >
                    <label
                      @click="$refs.select.focus()"
                      :class="{ error: errors[0] }"
                      for="location"
                      >Location
                      <span class="error">*</span>
                    </label>
                    <select
                      class="form-control form-control-lg"
                      id="location"
                      v-model="locationId"
                      v-bind="ariaInput"
                    >
                      <option :value="null">Select Park</option>
                      <option
                        v-for="item in locations"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.longName }}
                      </option>
                    </select>
                    <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                      <li
                        v-for="(error, index) in errors"
                        :key="index"
                        class="error"
                        v-bind="ariaMsg"
                      >
                        {{ error }}
                      </li>
                    </ul>
                  </ValidationProvider>
                </div>
                <div class="col-lg-4 pt-3">
                  <DateInput
                    vid="arrivalDate"
                    :rules="`${locationId ? 'required' : ''}|isDate`"
                    name="Arrival Date"
                    id="startDate"
                    v-model="startDate"
                  />
                </div>
                <div class="col-lg-4 pt-3">
                  <DateInput
                    vid="departureDate"
                    :rules="`${locationId ? 'required' : ''}|isDate`"
                    name="Departure Date"
                    id="endDate"
                    :dateDisabledFn="minDepartureDate"
                    :initialDate="initialDate"
                    v-model="endDate"
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-4">
                  <label for="loop">Loop</label>
                  <select
                    class="form-control form-control-lg"
                    id="loop"
                    v-model="loopId"
                  >
                    <option value="null">All</option>
                    <option
                      v-for="item in this.loops"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <div class="col-lg-4">
                  <TextInput
                    name="Spot Name"
                    id="spotName"
                    placeholder="Enter Spot Name"
                    v-model="spotName"
                    @change="handleInput"
                    :disabled="locationId == null"
                  />
                </div>
                <div
                  class="form-group col-lg-4 button-group"
                  v-if="!spotReservationId"
                >
                  <label for="walkInReservation" class="mr-2 float-left pt-1">
                    <input
                      type="checkbox"
                      :value="walkInReservation"
                      id="walkInReservation"
                      v-model="walkInReservation"
                    />
                    Walk-In Reservation</label
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <a
                    @click="showAdvancedSearch = !showAdvancedSearch"
                    href="javascript:void(0);"
                    role="button"
                    class="showFilter float-right"
                  >
                    Advanced Search
                    <i
                      class="fas fa-chevron-down"
                      v-if="!this.showAdvancedSearch"
                    ></i>
                    <i
                      class="fas fa-chevron-up"
                      v-if="this.showAdvancedSearch"
                    ></i>
                    {{
                      advancedSearchFilters > 0
                        ? "(" + advancedSearchFilters + ")"
                        : ""
                    }}
                  </a>
                  <hr class="px-0 mt-4 mb-1" />
                </div>
              </div>
              <div class="row mt-4" v-if="showAdvancedSearch">
                <div class="col-lg-4">
                  <SpotSearchParameters />
                </div>
                <div class="col-lg-8">
                  <div class="form-row col-12 text-left mx-0 mb-3 mt-0 p-0">
                    <p class="m-0">Spot Amenities</p>
                  </div>
                  <SpotAttributeFilters class="attrFilter" />
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-lg-4">
                  <div class="col-lg-12 pl-0 pt-3">
                    <button
                      type="button"
                      @click="showAllSpots"
                      class="btn btn-sm mr-2 float-left"
                      :class="
                        onlyShowAvailable ||
                        this.locationId == null ||
                        this.locationId == 0
                          ? 'btn-cancel'
                          : 'btn-primary'
                      "
                    >
                      Show All Spots
                    </button>
                    <button
                      type="button"
                      @click="showAvailableSpots"
                      class="btn btn-sm mr-2 float-left"
                      :class="!onlyShowAvailable ? 'btn-cancel' : 'btn-primary'"
                      :disabled="
                        this.locationId == null || this.locationId == 0
                      "
                    >
                      Show Available Spots
                    </button>
                  </div>
                </div>
                <div class="col-lg-8"></div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <div class="card-title mb-0">Spot Results</div>
            </div>
            <div class="card-body" v-if="locationId">
              <div v-show="!isParkLoading">
                <div class="mr-auto">
                  {{ displayedCount }} Spots match your selection(s)
                </div>
                <div class="row m-0">
                  <div class="col-12 px-0">
                    <hr class="px-0 mt-1 mb-1" />
                  </div>
                </div>
                <ClassificationFilters
                  :classifications="filteredClassifications"
                  :classCounts="classCounts"
                />
                <div class="row m-0">
                  <div class="col-12 px-0">
                    <hr class="px-0 mt-1 mb-1" />
                  </div>
                </div>
                <SpotResultsCalendar
                  :locationId="+locationId"
                  :spotReservationId="spotReservationId"
                  :adminView="true"
                  :walkInReservation="walkInReservation"
                  @getCount="getCount"
                  @updateClassCounts="updateClassCounts"
                  @loadingPark="loadingPark"
                />
              </div>
              <div class="text-center" v-show="isParkLoading">
                <span
                  class="spinner-border spinner-border-sm my-4"
                  role="status"
                  aria-hidden="true"
                ></span>
              </div>
            </div>
            <div v-else>
              <p class="m-4">Please select a park.</p>
            </div>
          </div>
          <button
            v-if="showBack"
            type="button"
            @click="goBack"
            class="btn btn-lg btn-primary mr-2 float-left"
          >
            Back
          </button>
          <button
            type="button"
            @click="goHome"
            class="btn btn-cancel btn-lg mr-2 float-left"
          >
            Cancel
          </button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import LocationService from "@/services/LocationService.js";
import AdminLocationService from "@/services/admin/AdminLocationService.js";
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import SpotResultsCalendar from "@/components/parkdetails/SpotResultsCalendar.vue";
import SpotSearchParameters from "@/components/parkdetails/SpotSearchParameters.vue";
import SpotAttributeFilters from "@/components/parkdetails/SpotAttributeFilters.vue";
import DateInput from "@/validation/DateInput.vue";
import moment from "moment";
import ClassificationFilters from "@/components/parkdetails/ClassificationFilters.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";
import TextInput from "@/validation/TextInput.vue";

export default {
  name: "AdminReservationSpotSelection",
  mixins: [checkPermissionMixin],
  components: {
    SpotSearchParameters,
    SpotAttributeFilters,
    DateInput,
    SpotResultsCalendar,
    ClassificationFilters,
    ValidationObserver,
    ValidationProvider,
    FormErrorAlert,
    TextInput
  },
  props: {
    heading: String,
    reservationLocationId: { type: Number, default: null },
    spotReservationId: { type: Number, default: null },
    showBack: { type: Boolean, default: false }
  },
  data() {
    return {
      errors: [],
      productClassifications: [],
      locations: [],
      loops: [],
      locationId: this.reservationLocationId,
      loopId: null,
      displayedCount: 0,
      classCounts: {},
      isParkLoading: false,
      walkInReservation: false,
      showAdvancedSearch: false,
      spotName: null
    };
  },
  methods: {
    loadingPark(isLoading) {
      this.isParkLoading = isLoading;
    },
    goHome() {
      window.scrollTo(0, 0);
      this.$store.dispatch("transaction/clear");
      this.$router.push("/admin").catch(() => {});
    },
    goBack() {
      this.$emit("goBack");
    },
    getCount(count) {
      this.displayedCount = count;
    },
    updateClassCounts(counts) {
      this.classCounts = counts;
    },
    showAvailableSpots() {
      if (this.startDate == null || this.endDate == null) {
        this.onSubmit();
        return;
      } else {
        this.errors = [];
        this.$store.commit("search/setSelectedProductClassifications", null);
        this.$store.commit("search/setOnlyShowAvailable", true);
      }
    },
    showAllSpots() {
      if (this.locationId == null || this.locationId == 0) {
        this.onSubmit();
        return;
      }
      this.$store.commit("search/setSelectedProductClassifications", null);
      this.$store.commit("search/setOnlyShowAvailable", false);
    },
    onSubmit() {
      this.$refs.dateObserver.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.dateObserver.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.dateObserver.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
        }
      });
    },
    async getParks() {
      var locationService = new LocationService(this.tenantId, null);
      const locations = await locationService.getAllLocations();
      this.locations = locations.filter(x =>
        this.checkLocationPermission("ReservationManagementAdd", x.id)
      );
    },
    minDepartureDate(ymd) {
      if (this.startDate) {
        return moment(this.startDate, "MM-DD-YYYY")
          .add(-1, "day")
          .isSameOrAfter(ymd, "day");
      }
      return;
    },
    loadProductClassifications() {
      if (!this.locationId) return;
      const locationService = new AdminLocationService(this.tenantId);
      locationService
        .getClassificationsWithSpotTypes(this.locationId)
        .then(response => {
          this.productClassifications = response.productClassificationsWithSpotTypes.map(
            x => {
              return {
                ...x,
                classification: {
                  ...x.classification,
                  name: `${x.classification.name} - P${(
                    x.classification.id + ""
                  ).padStart(3, "0")}`
                }
              };
            }
          );
        });
    },
    async loadLoops() {
      if (!this.locationId) return;
      const loopupService = new AdminLookupService(this.tenantId);
      this.loops = await loopupService.getLoopsForLocation(this.locationId);
    },
    handleInput(e) {
      if (e.target.value !== "") {
        this.$store.commit("search/setSelectedSpotName", e.target.value);
      } else {
        this.$store.commit("search/setSelectedSpotName", null);
      }
    }
  },
  computed: {
    selectedSpotTypes() {
      const selected = this.$store.getters["search/selectedSpotTypes"];
      return selected ? selected : [];
    },
    selectedSpotIsDayUse() {
      return this.$store.getters["search/selectedSpotIsDayUse"];
    },
    selectedAttributes() {
      const selected = this.$store.getters["search/selectedAttributes"];
      return selected ? selected : [];
    },
    advancedSearchFilters() {
      return this.selectedAttributes.length + this.selectedSpotTypes.length;
    },
    filteredClassifications() {
      const locationService = new LocationService(
        this.tenantId,
        this.locationId
      );
      let filteredClassifications = locationService.filterClassificationsFromSpotTypes(
        this.selectedSpotTypes,
        this.productClassifications
      );

      filteredClassifications = filteredClassifications.sort((a, b) => {
        return a.classification.name < b.classification.name
          ? -1
          : a.classification.name > b.classification.name
          ? 1
          : 0;
      });

      return filteredClassifications;
    },
    onlyShowAvailable() {
      return this.$store.getters["search/onlyShowAvailable"];
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    startDate: {
      get() {
        return this.$store.getters["search/startDate"];
      },
      set(val) {
        // if it matches MM/DD/YYYY set, else dont
        if (
          val &&
          val.match(
            /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/
          )
        )
          this.$store.commit("search/setSearchDate", {
            key: "startDate",
            value: val
          });
      }
    },
    endDate: {
      get() {
        return this.$store.getters["search/endDate"];
      },
      set(val) {
        // if it matches MM/DD/YYYY set, else dont
        if (
          val &&
          val.match(
            /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/
          )
        )
          this.$store.commit("search/setSearchDate", {
            key: "endDate",
            value: val
          });
      }
    },
    initialDate() {
      if (this.startDate) {
        return moment(this.startDate, "MM-DD-YYYY").format("YYYY-MM-DD");
      } else {
        return "";
      }
    }
  },
  watch: {
    locationId() {
      this.loopId = null;
      this.spotName = null;
      this.$refs.dateObserver.reset();
      this.errors = [];
      this.$store.commit("search/setOnlyShowAvailable", false);
      this.$store.commit("search/setSelectedProductClassifications", []);
      this.loadProductClassifications();
      this.loadLoops();
    },
    loopId() {
      this.$store.commit("search/setSelectedLoopId", this.loopId);
    }
  },
  created() {
    this.getParks();
    this.loadProductClassifications();
    this.loadLoops();
  }
};
</script>

<style scoped>
.label {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.heading {
  font-size: x-large;
}
.parameters {
  min-height: 200px;
  padding: 10px;
}
.attrFilter {
  max-height: 240px !important;
  overflow-x: hidden;
  overflow-y: auto;
}
.btn {
  border-radius: 0.3rem;
}
.button-group {
  padding-top: 2.2rem;
}
.showFilter {
  color: #1078a8 !important;
}
</style>
