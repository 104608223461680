<template>
  <div v-if="reservation">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title mt-2 mb-0">
          {{ heading }}
          <p class="mb-0 float-right">
            <span class="label" v-if="this.reservation.reservationNumber"
              >Reservation Number:</span
            >
            {{ reservation.reservationNumber }}
          </p>
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4 col-sm-12">
            <p class="mb-1">
              <span class="label">Primary Occupant:</span>
              {{ reservation.primaryOccupantName }}
            </p>
            <p class="mb-1">
              <span class="label">Arrival Date:</span>
              {{ reservation.arrivalDate | formatDate("MM/DD/YYYY") }}
            </p>
            <p
              class="mb-1"
              v-if="
                this.reservation.subStatus == 'CheckedIn' ||
                  this.reservation.subStatus == 'CheckedOut'
              "
            >
              <span class="label">Check-In Date:</span>
              {{ reservation.checkedInDate | formatDate("MM/DD/YYYY") }}
            </p>
            <p class="mb-1" v-if="this.reservation.subStatus == 'CheckedOut'">
              <span class="label">Check-Out Date:</span>
              {{ reservation.checkedOutDate | formatDate("MM/DD/YYYY") }}
            </p>
            <p class="mb-1">
              <span class="label">Park:</span> {{ reservation.parkName }}
            </p>
            <p class="mb-1">
              <span class="label">Classification:</span>
              {{ reservation.classification
              }}{{
                reservation.classificationId
                  ? ` - P${(reservation.classificationId + "").padStart(
                      3,
                      "0"
                    )}`
                  : ""
              }}
            </p>
            <p class="mb-1">
              <span class="label">Number of Vehicles:</span>
              {{ reservation.numberOfVehicles }}
            </p>
          </div>
          <div class="col-md-4 col-sm-12">
            <p class="mb-1">
              <span class="label">Booking Type:</span>
              {{ reservation.bookingType }}
            </p>
            <p class="mb-1">
              <span class="label">Departure Date:</span>
              {{ reservation.departureDate | formatDate("MM/DD/YYYY") }}
            </p>
            <p class="mb-1">
              <span class="label">Loop:</span> {{ reservation.loopName }}
            </p>
            <p class="mb-1">
              <span class="label">Product Type:</span>
              {{ reservation.productType }}
            </p>
            <p class="mb-1">
              <span class="label">Equipment Type:</span>
              {{ reservation.equipmentType }}
            </p>
          </div>
          <div class="col-md-4 col-sm-12">
            <p class="mb-1">
              <span class="label">Sales Channel:</span>
              {{ reservation.salesChannel | formatEnum }}
            </p>
            <p class="mb-1">
              <span class="label">Spot:</span> {{ reservation.spotName }}
            </p>
            <p class="mb-1">
              <span class="label">Number of People:</span>
              {{ reservation.numberOfAdults }}
            </p>
            <p class="mb-1">
              <span class="label">Equipment Length (ft):</span>
              {{ reservation.equipmentLength }}
            </p>
            <p class="mb-1">
              <span class="label">Walk-In Reservation:</span>
              {{ reservation.walkInReservation | yesNo }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminReservationSummaryDetail",
  props: {
    reservation: Object,
    heading: String
  }
};
</script>

<style scoped>
.label {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.heading {
  font-size: x-large;
}
</style>
