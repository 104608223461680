<template>
  <div>
    <AdminReservationSummaryDetail
      :reservation="reservation"
      heading="Currently Modifying"
      class="mx-4"
    />
    <AdminReservationSpotSelection
      heading="Reservation Edit - Park/Date/Spot"
      :reservationLocationId="reservation.locationId"
      :spotReservationId="+reservationId"
      v-if="reservation.spotId"
      @goBack="goBack"
      :showBack="true"
    />
  </div>
</template>

<script>
import AdminReservationSpotSelection from "@/components/admin/AdminReservationSpotSelection.vue";
import AdminSpotReservationService from "@/services/admin/AdminSpotReservationService.js";
import moment from "moment";
import AdminReservationSummaryDetail from "@/components/admin/AdminReservationSummaryDetail.vue";

export default {
  name: "AdminReservationEditSpot",
  title: "Admin - Reservation Edit Spot",
  components: { AdminReservationSpotSelection, AdminReservationSummaryDetail },
  props: {
    reservationId: String
  },
  data() {
    return {
      reservation: {}
    };
  },
  methods: {
    goBack() {
      window.scrollTo(0, 0);
      this.$store.dispatch("transaction/clear");
      this.$store.dispatch("search/clear");
      this.$router
        .push("/admin/reservation-details/" + this.reservationId)
        .catch(() => {});
    },
    async getReservationDetail() {
      if (!this.reservationId) return;

      if (this.currentReservation.oldSpotId != null) {
        this.reservation = this.currentReservation;
        window.scrollTo(0, 0);
        return;
      }

      const spotReservationService = new AdminSpotReservationService(
        this.tenantId
      );
      const response = await spotReservationService.getReservationDetail(
        this.reservationId
      );
      const reservation = response.data;
      const { arrivalDate, departureDate, spotId } = reservation;
      reservation.discounts = reservation.discounts.map(x => x.discountClassId);
      this.$store.commit("transaction/setReservation", {
        oldStartDate: arrivalDate,
        oldEndDate: departureDate,
        oldSpotId: spotId,
        oldDiscounts: reservation.discounts,
        startDate: arrivalDate,
        endDate: departureDate,
        reservationId: this.reservationId,
        ...reservation
      });
      this.$store.commit("search/setSelectedSpotId", spotId);
      this.$store.commit("search/setSearchDate", {
        key: "startDate",
        value: moment(arrivalDate, "MM/DD/YYYY").format("MM-DD-YYYY")
      });
      this.$store.commit("search/setSearchDate", {
        key: "endDate",
        value: moment(departureDate, "MM/DD/YYYY").format("MM-DD-YYYY")
      });
      this.reservation = reservation;
    },
    checkErrorQueue() {
      const errors = this.$store.getters["alert/errorQueue"];
      errors.forEach(x => {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: x,
          layer: "admin"
        });
      });
      this.$store.commit("alert/setErrorQueue", []);
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    currentReservation() {
      return this.$store.getters["transaction/reservation"];
    }
  },
  created() {
    this.getReservationDetail();
    this.checkErrorQueue();
  }
};
</script>

<style></style>
